<template>
  <router-view />
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PingFangSC-Regular", "Arial", "Microsoft YaHei", "黑体", "宋体",
    sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
a {
  color: #333;
}
a:hover {
  color: #00c6ff;
}
ul,
li,
ol {
  list-style: none;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.justify-between {
  justify-content: space-between;
}
.page-title {
  display: flex;
  font-weight: bold;
  color: #333;
  font-size: 24px;
  padding: 35px 0;
  align-items: flex-end;
  justify-content: space-between;
  em {
    font-size: 24px;
    font-style: normal;
  }
  .more {
    color: #999;
    font-size: 14px;
    font-weight: normal;
    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: normal;
      color: #999;
    }
  }
}
</style>
