import { createRouter, createWebHashHistory } from "vue-router";

const routes = [{
    path: "/",
    redirect: "/home"
},
{
    path: "/home",
    name: "home",
    component: () => import("@/views/home.vue"),
    meta: {
        title: "首页",
        show: true
    }
},
{
    path: "/about",
    name: "about",
    component: () => import("@/views/about.vue"),
    meta: {
        title: "公司介绍",
        show: true
    }
},
{
    path: "/drugList",
    name: "drugList",
    component: () => import("@/views/drugList.vue"),
    meta: {
        title: "药品展示",
        show: true
    }
},
{
    path: "/networkHospital",
    name: "networkHospital",
    component: null,
    meta: {
        title: "芝兰互联网医院",
        show: true
    }
},
{
    path: "/contactUs",
    name: "contactUs",
    component: () => import("@/views/contactUs.vue"),
    meta: {
        title: "联系我们",
        show: true
    }
},
{
    path: "/drugDetails",
    name: "drugDetails",
    component: () => import("@/views/drugDetails.vue"),
    meta: {
        title: "药品详情",
        show: false
    }
}

]
const router = createRouter({
    history: createWebHashHistory(),
    routes,
    linkActiveClass: 'active'
});

router.beforeEach((to) => {
    if (to.fullPath === '/networkHospital') {
        window.location.href = "http://www.slan-health.com/hospital.html";
        return false;
    }
    return true
})

export default router;
